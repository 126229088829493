<template>
  <ion-page style="background-color: #e5e5e5">
    <ion-header>
      <ion-toolbar class="toolbar-header">
        <ion-label class="fw-600 ml-2">{{ $t('payment_summary') }}</ion-label>
      </ion-toolbar>
    </ion-header>
    <div class="mt-5 mb-2 d-flex align-center justify-center flex-column">
      <ion-icon
        class="f-icon"
        :class="paymentStatusSelected.color"
        :icon="paymentStatusSelected.icon"
      ></ion-icon>
      <ion-label class="fs-4 fw-600 mt-2 mb-2" :class="paymentStatusSelected.color">
        {{ paymentStatusSelected.label }}</ion-label
      >
    </div>
    <ion-content>
      <ion-grid class="my-2 box px-2 py-2">
        <ion-row class="mb-2 mx-1 ion-justify-content-between">
          <ion-label class="text-gray-700">{{ `Invoice(s) Amount` }}</ion-label>
          <ion-label style="float: right" class="fw-600 text-gray-700"
            >{{ currencySymbol }}{{ invoiceAmount.toFixed(2) }}</ion-label
          >
        </ion-row>
        <ion-row class="mb-2" v-for="(invoice, i) in invoicesData" :key="i">
          <ion-col push="0.3">
            <ion-label class="text-gray-700">{{ invoice.label }}</ion-label>
          </ion-col>
          <ion-col size="auto">
            <ion-label class="fw-600 text-gray-700">{{ invoice.value }}</ion-label>
          </ion-col>
        </ion-row>
        <ion-row
          v-for="(item, idx) in dataSummary"
          :key="idx"
          class="mb-2"
          :class="{ 'border-bottom': [1, 2, 4].includes(idx) }"
        >
          <ion-col>
            <ion-label
              class="text-gray-700"
              :class="{
                'text-primary-green-600': idx === 0,
                'fw-700': idx == 5,
                'fw-500': [1, 2, 0].includes(idx)
              }"
              >{{ item.label }}</ion-label
            >
          </ion-col>
          <ion-col size="auto">
            <ion-text
              class="fw-600 text-gray-700"
              :class="
                idx === 5 ? `${paymentStatusSelected.color}` : idx === 0 ? 'text-primary-green-600' : ''
              "
            >
              {{ item.value }}</ion-text
            >
          </ion-col>
        </ion-row>
        <ion-row>
          <ion-col> </ion-col>
          <ion-col size="auto">
            <ion-text class="fw-700 text-gray-700">
              {{ currencySymbol }}{{ totalPaidAmount.toFixed(2) }}</ion-text
            >
          </ion-col>
        </ion-row>
        <ion-button class="my-2" @click="$emit('backToInvoice')" expand="block" color="primary">
          <ion-label class="text-capitalize">
            {{ $t('back_to_invoice') }}
          </ion-label>
        </ion-button>
        <ion-button class="mb-4" @click="$emit('backToHome')" fill="outline" expand="block">
          <ion-label class="text-capitalize">
            {{ $t('back_to_home') }}
          </ion-label>
        </ion-button>
      </ion-grid>
    </ion-content>
  </ion-page>
</template>
<script>
import { INVOICE_PAYMENT_TYPE } from '@/modules/shared/constants/';
import { alertCircleOutline, checkmarkCircleOutline, closeCircleOutline, timerOutline } from 'ionicons/icons';
import moment from 'moment';
import { defineComponent, ref } from 'vue';
import { useI18n } from 'vue-i18n';

export default defineComponent({
  name: 'payment-summary-digital',
  emits: ['backToInvoice', 'backToHome'],
  props: {
    paymentSummary: {
      type: Object,
      default: () => {}
    },
    currencySymbol: {
      type: String,
      default: 'S$'
    },
    invoices: {
      type: Array,
      default: () => []
    },
    appliedCredit: {
      type: Number,
      default: 0
    },
    totalPaidAmount: {
      type: Number,
      default: 0
    },
    grandTotal: {
      type: Number,
      default: 0
    },
    paymentType: {
      type: Number,
      default: INVOICE_PAYMENT_TYPE.CREDIT_CARD
    }
  },
  setup(props) {
    const { t } = useI18n();
    const paymentStatusValue = ref(props.paymentSummary?.details?.batchPayment?.status);
    const paymentStatus = [
      {
        label: t('payment_success'),
        icon: checkmarkCircleOutline,
        color: 'text-primary-green-600',
        btmLabel: t('paid').toUpperCase(),
        value: 'approved'
      },
      {
        label: t('payment_failed'),
        icon: closeCircleOutline,
        color: 'text-error-600',
        btmLabel: t('failed').toUpperCase(),
        value: 'rejected'
      },
      {
        label: t('payment_pending'),
        icon: alertCircleOutline,
        color: 'text-primary-orange-500',
        btmLabel: t('pending').toUpperCase(),
        value: 'pending'
      }
    ];
    const paymentStatusSelected =
      props.paymentType === INVOICE_PAYMENT_TYPE.CUSTOMER_CREDIT
        ? paymentStatus[0]
        : paymentStatus.find((item) => item.value === paymentStatusValue.value);
    const labelOptions = {
      [INVOICE_PAYMENT_TYPE.CREDIT_CARD]: 'Credit / Debit Card',
      [INVOICE_PAYMENT_TYPE.CUSTOMER_CREDIT]: 'Customer Credit',
      [INVOICE_PAYMENT_TYPE.NETS]: 'Nets'
    };
    const paymentMethod = ref(labelOptions[props.paymentType]);
    const invoicesData = props.invoices.map((invoice) => {
      return {
        label: invoice.invoiceNumber,
        value: `${props.currencySymbol}${invoice.outstandingAmount.toFixed(2) ?? 0}`
      };
    });
    const invoiceAmount = props.invoices.reduce((acc, invoice) => {
      const sum = acc + invoice.outstandingAmount ?? 0;
      return sum;
    }, 0);
    const valueDataSummary = (value) => `${props.currencySymbol}${value.toFixed(2) ?? '0.00'}`;

    const dataSummary = [
      {
        label: t('applied_credits'),
        value: valueDataSummary(props.appliedCredit)
      },
      {
        label: t('sub_total'),
        value: valueDataSummary(props.grandTotal)
      },
      {
        label: t('total_payment'),
        value: valueDataSummary(props.totalPaidAmount)
      },
      {
        label: t('payment_method'),
        value: paymentMethod.value ?? 'Debit'
      },
      {
        label: t('transaction_date'),
        value: moment(new Date(props.paymentSummary?.transactionDate)).format('DD MMM YYYY')
      },
      {
        label: t('total'),
        value: paymentStatusSelected.btmLabel
      }
    ];
    return {
      invoicesData,
      invoiceAmount,
      paymentStatus,
      dataSummary,
      paymentStatusSelected,
      timerOutline
    };
  }
});
</script>
<style lang="scss" scoped>
.f-icon {
  font-size: 3rem;
}
ion-content {
  --ion-background-color: #e5e5e5;
}

ion-button {
  width: 95%;
  margin: 0 auto;
}
.box {
  border: 1px solid var(--ion-color-text-gray-300);
  background-color: #fff;
  box-shadow: 1px 1px 5px 0px rgba(0, 0, 0, 0.2);
  border-radius: 10px !important;
  width: 94%;
  margin: 0 auto;
}
</style>
