<template>
  <ion-page>
    <ion-header>
      <ion-toolbar class="toolbar-header">
        <ion-icon
          @click="goBack"
          size="large"
          color="primary"
          slot="start"
          :icon="chevronBackOutline"
        ></ion-icon>
        <ion-label class="fw-600" @click="goBack">{{ $t('select_payment') }}</ion-label>
      </ion-toolbar>
    </ion-header>
    <skeleton-select-payment v-if="loadingInit" />
    <ion-content v-else>
      <ion-grid class="mb-2">
        <ion-row class="ion-align-items-center">
          <ion-col>
            <ion-row>
              <div class="d-flex align-center ml-1">
                <ion-icon class="i-help" :icon="helpCircleOutline" @click="openHelp"></ion-icon>
                <ion-label class="fs-2 ml-1 fw-500 text-gray-700">
                  {{ $t('use_available_credits') }}
                </ion-label>
              </div>
            </ion-row>
            <ion-row>
              <ion-label
                class="ml-6 mt-1 fw-500"
                :class="{
                  'text-primary-orange-500': remainingCredits <= 0,
                  'text-primary-green-500': remainingCredits > 0
                }"
                >{{ $t('remaining_credits') }}
                {{ priceFormatter(currencySymbol, remainingCredits) }}</ion-label
              >
            </ion-row>
          </ion-col>
          <ion-col size="auto">
            <ion-toggle
              ref="toggleRef"
              :checked="isChecked"
              @ionChange="toggleCredits"
              :disabled="isCreditAvailable"
            ></ion-toggle>
          </ion-col>
        </ion-row>
      </ion-grid>
      <div class="line mb-3"></div>
      <card-payment
        ref="cardPaymentRef"
        @onChangePaymentType="onPaymentSelected"
        :userType="userType"
        :listCards="saleListStripeCard.data"
        :isSgMyUser="isSgMyUser"
        :isFullyPaidCustBalance="isFullyPaidCustBalance"
        :totalPaidAmount="totalPaidAmount"
      />
      <div class="line mb-3"></div>
      <summary-info
        :selectedInvoices="selectedInvoicesData"
        :currencySymbol="currencySymbol"
        :customerCreditAllocation="customerCreditAllocation"
        :totalInvoicesAmount="totalInvoicesAmount"
        :totalInvoice="totalInvoicesAmount"
        :grandTotal="grandTotal"
        :totalPaidAmount="totalPaidAmount"
      />
      <div class="line mb-3"></div>
    </ion-content>
    <skeleton-bottom v-if="loadingInit" />
    <bottom-content
      v-else
      @handlePayInvoices="enterPayment"
      @editPayment="editPayment"
      :totalPaidAmount="totalPaidAmount"
      :currencySymbol="currencySymbol"
      :paymentType="paymentType"
      :userType="userType"
      :isDigitalPayment="isDigitalPayment"
      :isFullyPaidCustBalance="isFullyPaidCustBalance"
    />
    <ion-modal
      :initial-breakpoint="0.7"
      :is-open="toggleInputCredit"
      :backdropDismiss="true"
      @didDismiss="toggleCredits"
      class="bor-15"
    >
      <input-credits
        @close-modal="toggleInputCredit = false"
        @onUseCreditBalance="onUseCreditBalance"
        :currencySymbol="currencySymbol"
        :initialCredit="initialCreditValue"
        :remainingCredits="remainingCredits"
      />
    </ion-modal>
    <ion-modal
      :initial-breakpoint="0.7"
      :is-open="toggleEditPayment"
      :backdropDismiss="true"
      @didDismiss="toggleEditPayment = false"
      class="bor-15"
    >
      <edit-payment
        ref="editPaymentRef"
        @close-modal="toggleEditPayment = false"
        @onEditPaidAmount="onEditPaidAmount"
        :currencySymbol="currencySymbol"
        :totalOutstanding="grandTotal"
        :totalPaidAmount="totalPaidAmountFormatted"
        :minimumOutstandingAmount="minimumOutstandingAmount"
        :overdueAmounted="totalOverdue"
        :overpaymentAmounted="totalOverpayment"
      />
    </ion-modal>
    <ion-modal :is-open="isDigitalPaymentSummaryOpen" :backdropDismiss="true">
      <payment-summary
        @backToInvoice="backToInvoice"
        @backToHome="backToHome"
        :currencySymbol="currencySymbol"
        :paymentSummary="paymentSummary"
        :invoices="selectedInvoicesData"
        :paymentType="paymentType"
        :appliedCredit="customerCreditAllocation"
        :grandTotal="grandTotal"
        :totalPaidAmount="totalPaidAmount"
      />
    </ion-modal>
    <ion-toast
      :is-open="isErrorCreditAllocation"
      mode="ios"
      color="danger"
      :message="errorCreditAllocationMessage"
      :duration="2000"
      position="top"
      @didDismiss="isErrorCreditAllocation = false"
    >
    </ion-toast>
    <ion-loading
      :is-open="isLoadingAddCard"
      cssClass="custom-loading"
      message=""
      spinner="crescent"
      @didDismiss="setLoadingAddCard(false)"
    >
    </ion-loading>
  </ion-page>
</template>
<script>
// packages
import { ACTIONS } from '@/modules/sale/store/payment/actions';
import { INVOICE_PAYMENT_METHOD, INVOICE_PAYMENT_TYPE, USER_COUNTRY } from '@/modules/shared/constants/';
import Clevertap from '@/services/shared/helper/clevertap';
import { priceFormatter } from '@/utils/';
import { alertController } from '@ionic/vue';
import { chevronBackOutline, helpCircleOutline } from 'ionicons/icons';
import { defineComponent, ref } from 'vue';
import { useI18n } from 'vue-i18n';
import { useRouter } from 'vue-router';
import { createNamespacedHelpers } from 'vuex';

// components
import {
  InputCredits,
  SkeletonBottom,
  SkeletonSelectPayment
} from '@/modules/shared/components/invoices-payment';
import { BottomContent, CardPayment, EditPayment, PaymentSummary, SummaryInfo } from './components';

const { mapGetters, mapActions } = createNamespacedHelpers('sale/payment');

export default defineComponent({
  name: 'select-payment-sale',
  inject: ['$storage'],
  components: {
    BottomContent,
    SummaryInfo,
    CardPayment,
    InputCredits,
    EditPayment,
    SkeletonBottom,
    SkeletonSelectPayment,
    PaymentSummary
  },
  setup() {
    const router = useRouter();
    const { t } = useI18n();
    const toggleInputCredit = ref(false);
    const toggleEditPayment = ref(false);
    const isDigitalPaymentSummaryOpen = ref(false);
    const isChecked = ref(false);
    const isErrorCreditAllocation = ref(false);
    const errorCreditAllocationMessage = ref('');
    const userType = ref(null);
    const loadingInit = ref(false);
    const isLoadingAddCard = ref(false);
    const setOpenLoadingInit = (state) => {
      loadingInit.value = state;
    };
    const setLoadingAddCard = (state) => {
      isLoadingAddCard.value = state;
    };
    const goBack = () => {
      router.back();
    };

    const alert = async (cssClass, header, message = '', buttons) => {
      const alert = await alertController.create({
        backdropDismiss: false,
        cssClass,
        mode: 'ios',
        header: t(header),
        message: message ? t(message) : '',
        buttons
      });
      return alert.present();
    };
    const openHelp = alert.bind(
      this,
      'alert-blacklist-order',
      'not_sure_avail_credit',
      'not_sure_credit_desc',
      [
        {
          text: `${t('contact_us')}`,
          handler: () => {
            console.log('admin number');
          }
        },
        {
          text: t('close'),
          role: 'cancel'
        }
      ]
    );
    const backToInvoice = () => {
      isDigitalPaymentSummaryOpen.value = false;
      router.replace('/sale/invoices/select-invoices');
    };
    const backToHome = () => {
      isDigitalPaymentSummaryOpen.value = false;
      router.replace('/sale/main/home');
    };
    return {
      alert,
      userType,
      goBack,
      chevronBackOutline,
      toggleInputCredit,
      helpCircleOutline,
      isChecked,
      toggleEditPayment,
      openHelp,
      isErrorCreditAllocation,
      errorCreditAllocationMessage,
      priceFormatter,
      INVOICE_PAYMENT_TYPE,
      customerCreditAllocation: ref(0),
      remainingCredits: ref(0),
      totalOverpayment: ref(0),
      totalOverdue: ref(0),
      totalPaidAmount: ref(0),
      selectedInvoicesData: ref([]),
      loadingInit,
      setOpenLoadingInit,
      user: ref(null),
      digitalCardIdSelected: ref(null),
      isDigitalPaymentSummaryOpen,
      backToHome,
      setLoadingAddCard,
      isLoadingAddCard,
      backToInvoice,
      paymentType: ref(null),
      company: ref(null),
      isFullyPaidCustBalance: ref(false)
    };
  },
  ionViewDidEnter() {
    this.remainingCredits = this.availableCreditAmount;
    this.totalPaidAmount = this.grandTotal;
    this.selectedInvoicesData = this.selectedInvoices;
    this.totalOverpayment = 0;
    this.totalOverdue = 0;
    this.paymentType = null;
    this.$nextTick(() => {
      if (this.$refs.cardPaymentRef) {
        this.$refs.cardPaymentRef.paymentType = null;
        this.$refs.cardPaymentRef.digitalCardIdSelected = null;
      }
      if (this.$refs.toggleRef) {
        this.$refs.toggleRef.$el.checked = false;
      }
    });
  },
  watch: {
    customerCreditAllocation(value) {
      this.updateRemainingBalance(value);
      if (value > 0 && !this.paymentType) {
        this.paymentType = INVOICE_PAYMENT_TYPE.CUSTOMER_CREDIT;
        this.$refs.cardPaymentRef.paymentType = INVOICE_PAYMENT_TYPE.CUSTOMER_CREDIT;
      }
      if (value === +this.totalInvoicesAmount.toFixed(2)) {
        this.isFullyPaidCustBalance = true;
        this.paymentType = INVOICE_PAYMENT_TYPE.CUSTOMER_CREDIT;
        this.$refs.cardPaymentRef.paymentType = INVOICE_PAYMENT_TYPE.CUSTOMER_CREDIT;
        this.$refs.cardPaymentRef.digitalCardIdSelected = null;
      } else {
        this.isFullyPaidCustBalance = false;
      }
    },
    selectedInvoicesData(value) {
      this.customerCreditAllocation = 0;
      this.$nextTick(() => {
        if (this.$refs.toggleRef) {
          this.$refs.toggleRef.$el.checked = false;
        }
      });
      this.selectedInvoicesData = value;
    },
    isChecked(value) {
      this.isChecked = value;
    },
    totalPaidAmount(value) {
      this.totalPaidAmount = Number(value);
      if (this.customerCreditAllocation > 0 && this.totalPaidAmount === 0) {
        this.paymentType = INVOICE_PAYMENT_TYPE.CUSTOMER_CREDIT;
        this.$refs.cardPaymentRef.paymentType = INVOICE_PAYMENT_TYPE.CUSTOMER_CREDIT;
      }
    }
  },
  async mounted() {
    this.setOpenLoadingInit(true);
    const user = await this.$storage.getUser();
    this.company = await this.$storage.getSelectedCompany();
    this.userType = user.user_type_id;
    this.user = user;
    await this.handleGetCard();
  },
  computed: {
    ...mapGetters([
      'paymentSummary',
      'selectedPaymentInvoices',
      'saleListStripeCard',
      'defaultCardId',
      'error'
    ]),

    totalPaidAmountFormatted() {
      return Number(this.totalPaidAmount.toFixed(2));
    },
    selectedInvoices() {
      return this.selectedPaymentInvoices?.selectedInvoices ?? [];
    },
    totalInvoicesAmount() {
      return this.selectedPaymentInvoices?.totalInvoiceAmount;
    },
    currencySymbol() {
      return this.selectedPaymentInvoices?.selectedInvoices[0]?.currencySymbol;
    },
    availableCreditAmount() {
      return +this.selectedPaymentInvoices?.availableCreditAmount;
    },
    initialCreditValue() {
      if (this.availableCreditAmount > this.totalInvoicesAmount) {
        return this.totalInvoicesAmount;
      } else {
        return this.selectedPaymentInvoices?.availableCreditAmount - this.customerCreditAllocation;
      }
    },
    grandTotal() {
      return +this.totalInvoicesAmount.toFixed(2) - this.customerCreditAllocation;
    },
    isCreditAvailable() {
      return this.selectedPaymentInvoices?.maximumCreditAmount > 0;
    },
    minimumOutstandingAmount() {
      const invoices = this.selectedInvoices;
      const sortInvoices = invoices.sort(
        (invBefore, invAfter) => invBefore.invoiceDate - invAfter.invoiceDate
      );
      const sortedInvoices = sortInvoices.slice(0, 1);
      const invoiceAmount = sortedInvoices[0];
      return invoiceAmount.outstandingAmount;
    },
    isDigitalPayment() {
      return INVOICE_PAYMENT_METHOD.DIGITAL.includes(this.paymentType);
    },
    isSgMyUser() {
      return [USER_COUNTRY.SG, USER_COUNTRY.MY].includes(this.user?.country.id);
    }
  },
  methods: {
    ...mapActions([
      ACTIONS.SET_SELECTED_PAYMENT_INVOICES,
      ACTIONS.SALE_LIST_STRIPE_CARD,
      ACTIONS.CREATE_AUTOMATIC_BATCH_PAYMENTS
    ]),
    async handleGetCard() {
      Promise.allSettled([this[ACTIONS.SALE_LIST_STRIPE_CARD](this.company.id)]).then(() => {
        this.setOpenLoadingInit(false);
      });
    },
    toggleCredits(event) {
      if (event.type === 'did-dismiss') {
        if (this.customerCreditAllocation === 0) {
          this.$refs.toggleRef.$el.checked = false;
        }
        return;
      }
      this.toggleInputCredit = event.detail.checked;
      this.isChecked = event.detail.checked;
      if (!this.isChecked) this.customerCreditAllocation = 0;
      if (this.customerCreditAllocation > 0) this.isChecked = true;
      else {
        this.remainingCredits = this.availableCreditAmount;
        this.isChecked = false;
      }
    },
    onUseCreditBalance(amount) {
      if (+amount > this.remainingCredits) {
        this.isErrorCreditAllocation = true;
        this.errorCreditAllocationMessage = this.$t('input_amount_error');
        return;
      } else if (+amount > +this.grandTotal.toFixed(2)) {
        this.isErrorCreditAllocation = true;
        this.errorCreditAllocationMessage = this.$t('input_amount_error_2');
        return;
      } else if (+amount < 0) {
        this.isErrorCreditAllocation = true;
        this.errorCreditAllocationMessage = this.$t('invalid_amount_negative');
        return;
      } else {
        this.isErrorCreditAllocation = false;
        this.customerCreditAllocation = +amount;
      }
    },
    updateRemainingBalance(value) {
      this.remainingCredits = this.availableCreditAmount - value;
      this.totalPaidAmount = this.grandTotal;
    },
    editPayment() {
      this.toggleEditPayment = true;
    },
    onEditPaidAmount(totalPaidAmount, totalOverpayment, totalOverdue) {
      if (totalPaidAmount < 0) {
        this.isErrorCreditAllocation = true;
        this.errorCreditAllocationMessage = this.$t('invalid_amount_negative');
        return;
      }
      this.totalPaidAmount = totalPaidAmount;
      this.totalOverpayment = totalOverpayment;
      this.totalOverdue = totalOverdue;
    },
    onPaymentSelected(paymentType, digitalCardIdSelected) {
      this.paymentType = paymentType;
      this.digitalCardIdSelected = digitalCardIdSelected;
    },
    async enterPayment() {
      Clevertap.onUserProceedInvoices({
        companyName: this.company?.name?.trim(),
        invoices: this.selectedInvoices,
        totalAmountInvoices: this.totalInvoicesAmount.toFixed(2)
      });
      if (this.isDigitalPayment || this.isFullyPaidCustBalance) {
        this.setLoadingAddCard(true);
        await this.submitDigitalPayment();
        if (this.error) {
          this.setLoadingAddCard(false);
          this.alert('my-custom-class', 'payment_creation_failed', this.error.message, [
            {
              text: this.$t('OK'),
              handler: () => {
                this.$router.replace('/sale/invoices/select-invoices');
              }
            }
          ]);
        } else {
          this.setLoadingAddCard(false);
          this.isDigitalPaymentSummaryOpen = true;
        }
      } else {
        await this.submitOtherPayment();
      }
    },
    async submitDigitalPayment() {
      const paramsCreatePayment = {
        paymentTypeId: this.isFullyPaidCustBalance ? INVOICE_PAYMENT_TYPE.CUSTOMER_CREDIT : this.paymentType,
        totalPaidAmount: parseFloat(this.totalPaidAmount.toFixed(2)),
        invoiceIDs: this.selectedInvoices.map((invoice) => invoice.id),
        balanceAllocationAmount: this.customerCreditAllocation,
        stripeCardId:
          this.paymentType === 7 || this.isFullyPaidCustBalance ? null : this.digitalCardIdSelected,
        stripeCustomerId: null
      };
      await this[ACTIONS.CREATE_AUTOMATIC_BATCH_PAYMENTS]({ paramsCreatePayment });
    },
    async submitOtherPayment() {
      await this[ACTIONS.SET_SELECTED_PAYMENT_INVOICES]({
        selectedInvoices: this.selectedInvoices,
        totalInvoiceAmount: +this.totalInvoicesAmount.toFixed(2),
        availableCreditAmount: this.availableCreditAmount,
        overdueAmount: +this.totalOverdue.toFixed(2),
        overPayment: +this.totalOverpayment.toFixed(2),
        totalPaidAmount: this.totalPaidAmount,
        customerCreditAllocation: this.customerCreditAllocation
      });
      this.$router.push({
        path: '/sale/invoices/payment-details',
        query: {
          'payment-type': this.paymentType
        }
      });
    }
  }
});
</script>

<style lang="scss" scoped>
.i-help {
  color: #eb8c31;
  font-size: 22px;
}
.bor-15 {
  --border-radius: 15px;
}
ion-toggle {
  height: 32px;
  width: 50px;
  --handle-background: #fff;
  --handle-background-checked: #fff;
  --background-checked: #00676a;
  --handle-width: 27px;
  --handle-height: 40px;
  --handle-border-radius: 16px;
  --handle-max-height: 28px;
  --handle-spacing: 2px;
  /* Required for iOS handle to overflow the height of the track */
  overflow: visible;
  contain: none;
}
.line {
  width: 100%;
  height: 6px;
  background: #eeeded;
}
</style>
